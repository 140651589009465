<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />

      <div v-if="indexFolha === 0">
        <br />
        <br />
        <br />
        <br />
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3>ULTRASSONOGRAFIA OBSTÉTRICA</h3>
        <h3>DOPPLER</h3>
        <h3>PERFIL CERVICAL</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
          
          
        </h4>
        <h3 v-else-if="elemento === 'feto0'">
          <strong> Feto 1</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto1'">
          <strong> Feto 2</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto2'">
          <strong> Feto 3</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto3'">
          <strong> Feto 4</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto4'">
          <strong> Feto 5</strong>
        </h3>
        <p v-else-if="elemento === 'espaço'"></p>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>

    <folha
      v-for="(elementos2, indexFolha2) in tamanhoElemento2"
      :key="indexFolha2"
    >
      <br />
      <br />
      <br />
      <h3>DOPPLER</h3>

      <br />
      <br />
      <div
        v-for="(elemento2, indexElemento2) in elementos2"
        :key="indexElemento2"
      >
        <h4 v-if="titulos.includes(elemento2)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento2 }}
          <p></p>
          <p></p>
        </h4>
        <h3 v-else-if="elemento2 === 'feto0'">
          <strong> Feto 1</strong>
        </h3>
        <h3 v-else-if="elemento2 === 'feto1'">
          <strong> Feto 2</strong>
        </h3>
        <h3 v-else-if="elemento2 === 'feto2'">
          <strong> Feto 3</strong>
        </h3>
        <h3 v-else-if="elemento2 === 'feto3'">
          <strong> Feto 4</strong>
        </h3>
        <h3 v-else-if="elemento2 === 'feto4'">
          <strong> Feto 5</strong>
        </h3>
        <p v-else-if="elemento2 === 'espaço'"></p>

        <div v-else>
          {{ elemento2 }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha2 + 1 == tamanhoElemento2.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>

    <folha
      v-for="(elementos3, indexFolha3) in tamanhoElemento3"
      :key="indexFolha3"
    >
      <br />
      <br />
      <br />
      <h3>PERFIL CERVICAL</h3>

      <br />
      <br />
      <div
        v-for="(elemento3, indexElemento3) in elementos3"
        :key="indexElemento3"
      >
        <h4 v-if="titulos.includes(elemento3)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento3 }}
          <p></p>
          <p></p>
        </h4>
        <h3 v-else-if="elemento3 === 'feto0'">
          <strong> Feto 1</strong>
        </h3>
        <h3 v-else-if="elemento3 === 'feto1'">
          <strong> Feto 2</strong>
        </h3>
        <h3 v-else-if="elemento3 === 'feto2'">
          <strong> Feto 3</strong>
        </h3>
        <h3 v-else-if="elemento3 === 'feto3'">
          <strong> Feto 4</strong>
        </h3>
        <h3 v-else-if="elemento3 === 'feto4'">
          <strong> Feto 5</strong>
        </h3>
        <p v-else-if="elemento3 === 'espaço'"></p>

        <div v-else>
          {{ elemento3 }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha3 + 1 == tamanhoElemento3.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },

    mediaIndice(i) {
      if (
        !this.laudo ||
        !this.laudo[i] ||
        !this.laudo[i].indicePulsatilidadeArteriaUterinaD ||
        !this.laudo[i].indicePulsatilidadeArteriaUterinaE
      )
        return '';
      return (
        (parseFloat(this.laudo[i].indicePulsatilidadeArteriaUterinaD) +
          parseFloat(this.laudo[i].indicePulsatilidadeArteriaUterinaE)) /
        2.0
      ).toFixed(2);
    },
  },

  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];
    let folha2 = [];
    let folha3 = [];

    const todos = [];
    let todos2 = [];
    let todos3 = [];

    for (let i = 0; i < this.laudo.length; i++) {
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push(`feto${i}`);
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      if (this.laudo.length > 1) folha.push('espaço');
      folha.push('espaço');
      folha.push(`História materna`);
      if (this.laudo[i].dataNascimento)
        folha.push(`Data de nascimento : ${this.dataFormatada(this.laudo[i].dataNascimento)}.`);
        if (this.laudo[i].anos || this.laudo[i].meses)
        folha.push(`${this.laudo[i].anos?this.laudo[i].anos + (this.laudo[i].anos > 1 ? ' anos':' ano'):''}
        ${this.laudo[i].anos && this.laudo[i].meses? ' e ':''}
        ${this.laudo[i].meses?this.laudo[i].meses + (this.laudo[i].meses > 1 ? ' meses':' mês'):''}
      
        .`);
        if (this.laudo[i].peso)
        folha.push(`${this.laudo[i].peso?"Peso: " + this.laudo[i].peso + ' kg.':""}${
      this.laudo[i].altura?" Altura: " + this.laudo[i].altura + ' cm.':""}${
      this.laudo[i].imc?" IMC: " + this.laudo[i].imc :""}.`);

      if (this.laudo[i].metodoConcepcao)
        folha.push(`Método de concepção : ${this.laudo[i].metodoConcepcao}.`);
        if (this.laudo[i].fuma)
        folha.push(`Tabagismo durante a gravidez: ${this.laudo[i].fuma}.`);

        if (this.laudo[i].hipertensao)
        folha.push(`Hipertensão: ${this.laudo[i].hipertensao}.`);
        if (this.laudo[i].diabetes)
        folha.push(`Diabetes : ${this.laudo[i].diabetes}${
      this.laudo[i].diabetes == 'Sim'? ',  '+ this.laudo[i].tipoDiabetes :''}.`);
      if (this.laudo[i].intecorrencia)
        folha.push(`Outras intercorrências:  ${this.laudo[i].intecorrencia}.`);
      if (this.laudo[i].medicacao)
        folha.push(`Uso de medicações: ${this.laudo[i].medicacao}${
      this.laudo[i].medicacao == 'Sim'? ',  '+ (this.laudo[i].usoMedicacao == 'outros'?
      this.laudo[i].outroUsoMedicacao
    :this.laudo[i].usoMedicacao
    ) :''}.`);




      if (this.laudo[i].indicacaoExame)
        folha.push(`Indicação do exame: ${this.laudo[i].indicacaoExame== 'Outros'? this.laudo[i].indicacaoExameOutros:this.laudo[i].indicacaoExame }.`);

      if (this.laudo.length == 1) folha.push(`Gestação única. `);
      if (this.laudo.length == 2) folha.push(`Gestação gemelar. `);
      if (this.laudo.length == 3) folha.push(`Gestação trigemelar. `);
      if (this.laudo.length == 4) folha.push(`Gestação quadrigemelar. `);
      if (this.laudo.length == 5) folha.push(`Gestação quíntuplo. `);
      if (folha.length > 0) folha.splice(0, 0, '');
      this.gestacaoz = [...folha];
      folha = [];

      if (this.laudo[i].situacao)
        folha.push(
          `Situação ${this.laudo[i].situacao}${
            this.laudo[i].apresentacao || this.laudo[i].dorso ? ',' : ''
          }${this.laudo[i].apresentacao ? ' apresentação ' : ''}${
            this.laudo[i].apresentacao ? this.laudo[i].apresentacao : ''
          }${this.laudo[i].dorso ? ' com dorso ' : ''}${
            this.laudo[i].dorso ? this.laudo[i].dorso : ''
          }${this.laudo[i].dorso2 ? ' ' + this.laudo[i].dorso2 : ''}.`
        );
      if (folha.length > 0) folha.splice(0, 0, 'Posição fetal');
      this.posiçãoFetal = [...folha];
      folha = [];

      if (this.laudo[i].diametroBiparietal && this.laudo[i].percentil1)
        folha.push(
          `DBP: ${this.laudo[i].diametroBiparietal} mm (percentil ${this.laudo[i].percentil1}).`
        );

      if (this.laudo[i].diametroBiparietal && !this.laudo[i].percentil1)
        folha.push(
          `DBP: ${this.laudo[i].diametroBiparietal} mm.`
        );
        if (this.laudo[i].dof )
        folha.push(
          `DOF: ${this.laudo[i].dof} mm.`
        );

      if (this.laudo[i].circunferenciaCraniana && this.laudo[i].percentil2)
        folha.push(
          `CC: ${this.laudo[i].circunferenciaCraniana} mm (percentil ${this.laudo[i].percentil2}).`
        );
      if (this.laudo[i].circunferenciaCraniana && !this.laudo[i].percentil2)
        folha.push(
          `CC: ${this.laudo[i].circunferenciaCraniana} mm.`
        );

      if (this.laudo[i].circunferenciaAbdominal && this.laudo[i].percentil3)
        folha.push(
          `CA: ${this.laudo[i].circunferenciaAbdominal} mm (percentil ${this.laudo[i].percentil3}).`
        );
      if (this.laudo[i].circunferenciaAbdominal && !this.laudo[i].percentil3)
        folha.push(
          `CA: ${this.laudo[i].circunferenciaAbdominal} mm.`
        );

      if (this.laudo[i].comprimentoFemoral && this.laudo[i].percentil4)
        folha.push(
          `CF: ${this.laudo[i].comprimentoFemoral} mm (percentil ${this.laudo[i].percentil4}).`
        );
      if (this.laudo[i].comprimentoFemoral && !this.laudo[i].percentil4)
        folha.push(
          `CF: ${this.laudo[i].comprimentoFemoral} mm.`
        );

      if (this.laudo[i].pesoFetal && this.laudo[i].percentil5)
        folha.push(
          `Peso fetal estimado de ${this.laudo[i].pesoFetal} gramas, correspondendo ao percentil ${this.laudo[i].percentil5}.`
        );
      if (this.laudo[i].pesoFetal && !this.laudo[i].percentil5)
        folha.push(`Peso fetal estimado de ${this.laudo[i].pesoFetal} gramas.`);
        if (this.laudo[i].ccca)
        folha.push(
          `CC/CA: ${this.laudo[i].ccca} %.`
        );
        if (this.laudo[i].cfcc)
        folha.push(
          `CF/CC: ${this.laudo[i].cfcc} %.`
        );
        if (this.laudo[i].cfca)
        folha.push(
          `CF/CA: ${this.laudo[i].cfca} %.`
        );
        if (this.laudo[i].indiceCefalico)
        folha.push(`Índice cefálico (DBP/DOF) ${this.laudo[i].indiceCefalico}.`);
       
      if (folha.length > 0) folha.splice(0, 0, 'Biometria fetal');
      this.biometriaFetal = [...folha];
      folha = [];

      if (
        this.laudo[i].batimentosCardiacosFetais &&
        this.laudo[i].batimentosCardiacosFetais != 'presentes'
      )
        folha.push(
          `Batimentos cardíacos fetais ${this.laudo[i].batimentosCardiacosFetais}}`
        );

      if (
        this.laudo[i].batimentosCardiacosFetais &&
        this.laudo[i].batimentosCardiacosFetais === 'presentes'
      )
        folha.push(
          `Batimentos cardíacos fetais ${
            this.laudo[i].batimentosCardiacosFetais
          }${
            this.laudo[i].batimentosCardiacosFetais &&
            this.laudo[i].batimentosCardiacosFetais2
              ? ', '
              : ''
          }${
            this.laudo[i].batimentosCardiacosFetais2
              ? this.laudo[i].batimentosCardiacosFetais2
              : ''
          }${
            this.laudo[i].batimentosCardiacosFetais2 &&
            this.laudo[i].frequenciabpm
              ? ', '
              : ''
          }${
            this.laudo[i].frequenciabpm
              ? 'com frequência cardíaca de ' +
                this.laudo[i].frequenciabpm +
                ' bpm'
              : ''
          }.`
        );

      if (this.laudo[i].movimentosCorporais)
        folha.push(
          `Movimentos corporais ${this.laudo[i].movimentosCorporais}. `
        );
      if (this.laudo[i].tonus) folha.push(`Tônus ${this.laudo[i].tonus}. `);
      if (this.laudo[i].volumeLíquidoAmniotico)
        folha.push(
          `Volume de líquido amniótico ${this.laudo[i].volumeLíquidoAmniotico}${
            this.laudo[i].percentil6 ? ' com maior bolsão medindo ' : ''
          }${this.laudo[i].percentil6 ? this.laudo[i].percentil6 : ''}${
            this.laudo[i].percentil6 ? ' cm' : ''
          }. `
        );

      if (this.laudo[i].placenta)
        folha.push(
          `Placenta ${this.laudo[i].placenta}, ${
            this.laudo[i].insercao
              ? 'de inserção ' + this.laudo[i].insercao + ','
              : ''
          }${
            this.laudo[i].grau
              ? ' grau ' +
                this.laudo[i].grau +
                ' segundo a classificação de Grannum'
              : ''
          }. `
        );

      if (this.laudo[i].comentariosAdcionaisMeio1)
        folha.push(`${this.laudo[i].comentariosAdcionaisMeio1}`);

      if (folha.length > 0) folha.splice(0, 0, 'Vitalidade fetal');
      this.vitalidadeFetal = [...folha];
      folha = [];
      if (this.laudo.length == 1) folha.push(`Gestação tópica, única, feto ${this.laudo[i].estadoFeto}.  `);
      if (this.laudo.length == 2)
        folha.push(
          `Gestação tópica, gemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}, feto ${this.laudo[i].estadoFeto}.`
        );
      if (this.laudo.length == 3)
        folha.push(
          `Gestação tópica, trigemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}, feto ${this.laudo[i].estadoFeto}.`
        );
      if (this.laudo.length == 4)
        folha.push(
          `Gestação tópica, quadrigemelar ${
            this.laudo[i].gestacaoTres ? this.laudo[i].gestacaoTres : ''
          }${
            this.laudo[i].gestacaoQuatro && this.laudo[i].gestacaoTres
              ? ', '
              : ''
          }${this.laudo[i].gestacaoQuatro ? this.laudo[i].gestacaoQuatro : ''}, feto ${this.laudo[i].estadoFeto}.`
        );
      if (this.laudo.length == 5) folha.push(`Gestação tópica, quíntuplo, feto ${this.laudo[i].estadoFeto}. `);

      if (this.laudo.length == 2) folha.push(``);

      if (this.laudo.length > 2)
        folha.push(
          `${this.laudo[i].gestacaoCinco ? this.laudo[i].gestacaoCinco : ''}`
        );
        if (this.laudo[i].idadeGestacionalCorrigida2)
        folha.push(
          `Idade gestacional corrigida  ${
            this.laudo[i].idadeGestacionalCorrigida2
              ? this.laudo[i].idadeGestacionalCorrigida2 +
                (this.laudo[i].idadeGestacionalCorrigida2 > 1
                  ? ' semanas'
                  : ' semana')
              : ''
          }${
            this.laudo[i].idadeGestacionalCorrigida2 &&
            this.laudo[i].semanasCorrigida11
              ? ' e '
              : ''
          }${
            this.laudo[i].semanasCorrigida11
              ? this.laudo[i].semanasCorrigida11 +
                (this.laudo[i].semanasCorrigida11 > 1 ? ' dias' : ' dia')
              : ''
          }${
            this.laudo[i].compativelIncompativel
              ? ', ' +
                this.laudo[i].compativelIncompativel +
                ' com o datismo menstrual'
              : ''
          }.`
        );

        if (this.laudo[i].idadeGestacional2 || this.laudo[i].semanas11)
        folha.push(`Biometria fetal atual compatível com 
        ${
          this.laudo[i].idadeGestacional2
            ? this.laudo[i].idadeGestacional2 +
              (this.laudo[i].idadeGestacional2 > 1 ? ' semanas ' : ' semana ')
            : ''
        }
        ${
          this.laudo[i].idadeGestacional2 && this.laudo[i].semanas11
            ? ' e '
            : ''
        }
        ${
          this.laudo[i].semanas11
            ? this.laudo[i].semanas11 +
              (this.laudo[i].semanas11 > 1 ? ' dias ' : ' dia ')
            : ''
        }.`);

      if (this.laudo[i].dataProvavelParto)
        folha.push(
          `Data provável do parto pela biometria fetal atual (40 semanas): ${this.dataFormatada(
            this.laudo[i].dataProvavelParto
          )}.`
        );
      if (this.laudo[i].dataProvavelParto1Trimestre)
        folha.push(
          `Data provável do parto por ultrassonografia corrigida: ${this.dataFormatada(
            this.laudo[i].dataProvavelParto1Trimestre
          )}.`
        );

      // if (
      //   this.laudo[i].gestacaoConclusao &&
      //   this.laudo[i].gestacaoConclusao !=
      //     'Observamos discordância da idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual.'
      // )
      //   folha.push(`${this.laudo[i].gestacaoConclusao}`);
      // if (
      //   this.laudo[i].gestacaoConclusao &&
      //   this.laudo[i].gestacaoConclusao ===
      //     'Observamos discordância da idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual.'
      // )
      //   folha.push(
      //     `${this.laudo[i].gestacaoConclusao} sugerindo ${this.laudo[i].gestacaoConclusao2}`
      //   );

      if (this.laudo[i].gestacaoConclusao)
        folha.push(
          `${this.laudo[i].gestacaoConclusao}${
            this.laudo[i].gestacaoConclusao ==
            'Peso fetal abaixo do percentil 5, compatível com CIUR, estágio '
              ? this.laudo[i].conclusaoObs
              : ''
          }`
        );
      if (this.laudo[i].gestacaoConclusao3)
      folha.push(`${typeof this.laudo[i].gestacaoConclusao3 == "string"? this.laudo[i].gestacaoConclusao3:this.laudo[i].gestacaoConclusao3.join('.')}.`);
        if (this.laudo[i].gestacaoConclusao4)
        folha.push(`${this.laudo[i].gestacaoConclusao4}.`)
      if (this.laudo[i].comentariosAdcionaisFinal2)
        folha.push(`${this.laudo[i].comentariosAdcionaisFinal2}`);

      if (this.laudo[i].batimentosCardiacosFetais === 'ausentes')
        folha.push(`Óbito fetal.`);

      if (this.laudo[i].batimentosCardiacosFetais === 'ausentes')
        folha.push(
          `Idade gestacional de ${this.laudo[i].idadeGestacional} semanas.`
        );

      if (this.laudo[i].comentariosAdcionaisFinal1)
        folha.push(`${this.laudo[i].comentariosAdcionaisFinal1}`);

      if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
      this.conclusao = [...folha];

      folha = [];

      todos.push(...this.gestacaoz);
      todos.push(...this.posiçãoFetal);
      todos.push(...this.biometriaFetal);
      todos.push(...this.vitalidadeFetal);
      todos.push(...this.conclusao);
    }

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 37));
    }
    for (let j = 0; j < this.laudo.length; j++) {
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push(`feto${j}`);
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push('espaço');
      if (this.laudo.length > 1) folha2.push('espaço');
      folha2.push('espaço');
      if (
        this.laudo[j].indicePulsatilidadeArteriaUmbilical ||
        this.laudo[j].percentil
      )
        folha2.push('Artéria umbilical');

      if (
        this.laudo[j].indicePulsatilidadeArteriaUmbilical ||
        this.laudo[j].percentil
      )
        folha2.push(
          `${
            this.laudo[j].indicePulsatilidadeArteriaUmbilical
              ? 'Índice de pulsatilidade: '
              : ''
          }${
            this.laudo[j].indicePulsatilidadeArteriaUmbilical
              ? this.laudo[j].indicePulsatilidadeArteriaUmbilical
              : ''
          }${
            this.laudo[j].percentil18 &&
            this.laudo[j].indicePulsatilidadeArteriaUmbilical
              ? ' - '
              : ''
          }${this.laudo[j].percentil18 ? 'Percentil ' : ''}${
            this.laudo[j].percentil18 ? this.laudo[j].percentil18 : ''
          }${
            this.laudo[j].indiceOpcao3
              ? ' ' + this.laudo[j].indiceOpcao3 + ' para idade gestacional.'
              : '.'
          }`
        );
      if (this.laudo[j].diastole)
        folha2.push(`Diástole: ${this.laudo[j].diastole}.`);
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      folha2.push('espaço');
      if (folha2.length > 0) folha2.splice(0, 0);
      this.arteriaUmbilical = [...folha2];
      folha2 = [];

      if (this.laudo[j].indicePulsatilidadeArteriaCerebralMedia)
        folha2.push(
          `Índice de pulsatilidade: ${
            this.laudo[j].indicePulsatilidadeArteriaCerebralMedia
          }${
            this.laudo[j].percentil34 &&
            this.laudo[j].indicePulsatilidadeArteriaCerebralMedia
              ? ' - '
              : ''
          }${this.laudo[j].percentil34 ? ' Percentil ' : ''}${
            this.laudo[j].percentil34 ? ' ' + this.laudo[j].percentil34 : ''
          } ${this.laudo[j].indiceOpcao1} para idade gestacional.`
        );
      if (this.laudo[j].opcaoVelocidade)
        folha2.push(
          ` Velocidade de Pico Sistólica: ${this.laudo[j].opcaoVelocidade} cm/s, correspondendo a ${this.laudo[j].opcaoVelocidade2} múltiplos da mediana, ${this.laudo[j].opcaoVelocidade3}.`
        );
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push(
          `Relação cérebro-placentária: ${
            this.laudo[j].indicePulsatilidadeRelacaoCerebroPla
          }${
            this.laudo[j].percentil20 &&
            this.laudo[j].indicePulsatilidadeRelacaoCerebroPla
              ? ' - '
              : ''
          }${this.laudo[j].percentil20 ? ' Percentil ' : ''}${
            this.laudo[j].percentil20 ? this.laudo[j].percentil20 : ''
          }${
            this.laudo[j].indiceOpcaoCerePlac
              ? ' ' +
                this.laudo[j].indiceOpcaoCerePlac +
                ' para idade gestacional.'
              : '.'
          }`
        );
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeRelacaoCerebroPla)
        folha2.push('espaço');
      if (folha2.length > 0) folha2.splice(0, 0, 'Artéria cerebral média');
      this.arteriaCerebralMedia = [...folha2];
      folha2 = [];

      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push(
          `Índice de Pulsatilidade (direita): ${this.laudo[j].indicePulsatilidadeArteriaUterinaD}.`
        );
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaE)
        folha2.push(
          `Índice de Pulsatilidade (esquerda): ${this.laudo[j].indicePulsatilidadeArteriaUterinaE}.`
        );
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaE)
        folha2.push(
          `Índice de Pulsatilidade médio: ${
            this.laudo[j].indicePulsatilidadeArteriaUterinaE
              ? this.mediaIndice(j)
              : ''
          }${
            this.laudo[j].percentilArtMed
              ? ' - Percentil ' +
                this.laudo[j].percentilArtMed +
                (this.laudo[j].indiceOpcaoArtMed
                  ? ' ' +
                    this.laudo[j].indiceOpcaoArtMed +
                    ' para idade gestacional'
                  : '') +
                ''
              : ''
          }.`
        );
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (this.laudo[j].indicePulsatilidadeArteriaUterinaD)
        folha2.push('espaço');
      if (folha2.length > 0) folha2.splice(0, 0, 'Artéria uterina');
      this.arteriaUterinaDireita = [...folha2];
      folha2 = [];
      if (this.laudo[j].comentariosAdcionaisMeio2)
        folha2.push(`${this.laudo[j].comentariosAdcionaisMeio2}`);

      if (folha2.length > 0) folha2.splice(0, 0, '');
      this.arteriaUterinaEsquerda = [...folha2];
      folha2 = [];

      if (
        this.laudo[j].ductoVenoso &&
        this.laudo[j].ductoVenoso == 'Ducto Venoso'
      )
        folha2.push(
          `Índice de pulsatilidade: ${this.laudo[j].indicePulsatilidadeDuctoVenoso} ${this.laudo[j].indiceOpcao4} para idade gestacional.`
        );
      if (folha2.length > 0) folha2.splice(0, 0, 'Ducto venoso');
      this.ductoVenoso = [...folha2];
      folha2 = [];
      if (this.laudo[j].checkFluxoUmb)
        folha2.push(`Fluxo umbilical normal para a idade gestacional.`);
      if (this.laudo[j].checkAusFet)
        folha2.push(`Ausência de centralização fetal.`);
      if (this.laudo[j].checkRelCerNormal)
        folha2.push(
          `Relação cerebro-placentária normal para a idade gestacional.`
        );
      if (this.laudo[j].checkFluxoUmbRes)
        folha2.push(`Fluxo umbilical com resistência elevada.`);
      if (this.laudo[j].checkRelCerAcima)
        folha2.push(
          `Relação cerebro-placentária acima do percentil esperado para a idade gestacional. Convém monitorar.`
        );
      if (this.laudo[j].checkCentraFet) folha2.push(`Centralização fetal.`);
      if (this.laudo[j].checkDucto)
        folha2.push(`Ducto venoso ${this.laudo[j].ductoVenNormAlt}.`);
      if (this.laudo[j].checkIndiceAcima)
        folha2.push(
          `Indice de pulsatilidade médio acima do percentil esperado para a idade gestacional, aumentando o risco de pré-eclâmpsia e restrição de crescimento precoce.`
        );
      if (this.laudo[j].checkIndiceDentro)
        folha2.push(
          `Indice de pulsatilidade médio dentro do percentil normal para a idade gestacional, reduzindo o risco de pré-eclâmpsia e restrição de crescimento precoce.`
        );
        if (this.laudo[j].checkPicoNormal)
        folha2.push(
          `Pico da velocidade sistólica da artéria cerebral média normal.`
        );
      if (this.laudo[j].checkPicoAnemLeve)
        folha2.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia leve.`
        );
      if (this.laudo[j].checkPicoAnemMod)
        folha2.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia moderada.`
        );
      if (this.laudo[j].checkCentraFet)
        folha2.push(
          `Pico da velocidade sistólica da artéria cerebral média compatível com anemia grave`
        );

      if (this.laudo[j].fluxoUmbilicalOpcao1)
        folha2.push(
          `Fluxo umbilical ${this.laudo[j].fluxoUmbilicalOpcao1} para a idade gestacional, com diástole ${this.laudo[j].fluxoUmbilicalOpcao2}.`
        );
      if (this.laudo[j].centralizacaoFetal)
        folha2.push(
          `${this.laudo[j].centralizacaoFetal} de centralização fetal.`
        );
      if (this.laudo[j].relacaoCerebroPlacentaria)
        folha2.push(
          `Relação cérebro-placentária ${this.laudo[j].relacaoCerebroPlacentaria} para idade gestacional.`
        );
      if (
        this.laudo[j].ductoVenosoIndice &&
        this.laudo[j].ductoVenosoIndice ==
          'Ducto venoso com Índice de pulsatilidade'
      )
        folha2.push(
          ` Ducto venoso com Índice de pulsatilidade ${this.laudo[j].indiceOpcao5} para idade gestacional.`
        );
      if (
        this.laudo[j].arteriasUterinas &&
        this.laudo[j].arteriasUterinas ==
          'Artérias uterinas com média do Índice de pulsatilidade'
      )
        folha2.push(
          ` Artérias uterinas com média do Índice de pulsatilidade ${this.laudo[j].arteriasUterinas2} para idade gestacional. ${this.laudo[j].arteriasUterinas3} o risco de restrição de crescimento fetal e pré-eclâmpsia.`
        );
      if (this.laudo[j].velocidadeAcm)
        folha2.push(`${this.laudo[j].velocidadeAcm}.`);
      if (this.laudo[j].comentariosAdicionais)
        folha2.push(`${this.laudo[j].comentariosAdicionais}`);
      if (folha2.length > 0) folha2.splice(0, 0, 'Conclusão');
      this.conclusao2 = [...folha2];
      folha2 = [];
      todos2.push(...this.arteriaUmbilical);
      todos2.push(...this.arteriaCerebralMedia);
      todos2.push(...this.arteriaUterinaDireita);
      todos2.push(...this.arteriaUterinaEsquerda);
      todos2.push(...this.ductoVenoso);
      todos2.push(...this.conclusao2);
    }

    while (todos2.length > 0) {
      this.tamanhoElemento2.push(todos2.splice(0, 80));
    }

    for (let k = 0; k < this.laudo.length; k++) {
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push(`feto${k}`);
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push('espaço');
      if (this.laudo.length > 1) folha3.push('espaço');
      folha3.push('espaço');

      if (this.laudo[k].coloUterino || this.laudo[k].coloMedidaEmRepouco)
        folha3.push(
          `${this.laudo[k].coloUterino ? 'Colo uterino ' : ''}${
            this.laudo[k].coloUterino ? this.laudo[k].coloUterino : ''
          }${
            this.laudo[k].coloMedidaEmRepouco && this.laudo[k].coloUterino
              ? ', com menor comprimento de '
              : ''
          }${
            this.laudo[k].coloMedidaEmRepouco
              ? ' ' + this.laudo[k].coloMedidaEmRepouco + ' mm (colo avaliado por três minutos).'
              : '.'
          }`
        );

      // if (this.laudo[k].coloPassouAMedir || this.laudo[k].cmdois)
      //   folha3.push(
      //     `${
      //       this.laudo[k].coloPassouAMedir
      //         ? 'Após três minutos de avaliação, o colo '
      //         : ''
      //     }${
      //       this.laudo[k].coloPassouAMedir ? this.laudo[k].coloPassouAMedir : ''
      //     }${this.laudo[k].cmdois ? ' ' + this.laudo[k].cmdois + ' cm.' : '.'}`
      //   );

      if (
        this.laudo[k].orificioCervicalInterno &&
        this.laudo[k].orificioCervicalInterno == 'fechado'
      )
        folha3.push(
          ` Orifício cervical interno ${this.laudo[k].orificioCervicalInterno}.`
        );

      if (
        this.laudo[k].orificioCervicalInterno &&
        this.laudo[k].orificioCervicalInterno == 'aberto'
      )
        folha3.push(
          ` Orifício cervical interno ${this.laudo[k].orificioCervicalInterno} com ${this.laudo[k].orificioCervicalInterno2} mm.`
        );

      if (this.laudo[k].protusaoBolsaAmniotica)
        folha3.push(
          `${this.laudo[k].protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.`
        );
      if (this.laudo[k].sinalDeSludge)
        folha3.push(`Sinal de sludge ${this.laudo[k].sinalDeSludge}.`);

      if (
        this.laudo[k].polipoEndocervicalMedindo &&
        this.laudo[k].polipoEndocervicalMedindo ==
          '-------------------------------------'
      )
        folha3.push(` ${this.laudo[k].polipoEndocervicalMedindo}.`);

      if (
        this.laudo[k].polipoEndocervicalMedindo &&
        this.laudo[k].polipoEndocervicalMedindo ==
          'Observamos pólipo endocervical medindo'
      )
        folha3.push(
          ` ${this.laudo[k].polipoEndocervicalMedindo} ${this.laudo[k].polipoEndocervicalMedindoCm} mm.`
        );

      if (folha3.length > 0) folha3.splice(0, 0, '');
      this.coloUterino = [...folha3];
      folha3 = [];
      todos3.push(...this.coloUterino);

      folha3.push('espaço');

      if (this.laudo[k].coloUterino2)
        folha3.push(`Colo uterino ${this.laudo[k].coloUterino2}.`);

      if (this.laudo[k].comentarioAdcionalPerfilCervical)
        folha3.push(`${this.laudo[k].comentarioAdcionalPerfilCervical}.`);
      if (folha3.length > 0) folha3.splice(0, 0, 'Conclusão');
      this.conclusao3 = [...folha3];
      folha3 = [];
      todos3.push(...this.conclusao3);
    }

    while (todos3.length > 0) {
      this.tamanhoElemento3.push(todos3.splice(0, 32));
    }
  },

  data() {
    return {
      gestacaoz: [],
      via: '',
      posiçãoFetal: [],
      biometriaFetal: [],
      vitalidadeFetal: [],
      coloUterino: [],
      conclusao3: [],
      conclusao: [],
      tamanhoElemento: [],
      tamanhoElemento2: [],
      tamanhoElemento3: [],
      titulos: [
        'Gestação',
        'Posição fetal',
        'Biometria fetal',
        'Vitalidade fetal',
        'Indicação do exame',
        'Conclusão',
        'Estática',
        'Vitalidade',
        'Biometria Fetal',
        'Abdômen',
        'Morfologia Fetal',
        'Placenta',
        'Cordão Umbilical',
        'Artéria umbilical',
        'Artéria cerebral média',
        'Artéria Uterina direita',
        'Artéria Uterina esquerda',
        'Ducto Venoso',
        'Artéria uterina',
        'Artéria cerebral média',
        'Artéria Uterina direita',
        'Artéria Uterina esquerda',
        'Ducto venoso',
        'Colo Uterino',
        'Colo Uterino Conclusão',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: 0px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
